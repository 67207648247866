<script setup>
import { ref, computed } from 'vue'

const props = defineProps({
	items: {
		type: Array,
		required: true
	},
	isHaveSlot: {
        type: Boolean,
        default: false
    },
	imagesToShow:{
		type: Number,
        default: 5
	},
	arrowWidth: {
		type: Number,
        default: 10
	}

})

const imagesToShow = props.imagesToShow
const currentIndex = ref(0)

// Total slides now based on all images minus those already in view
const maxIndex = computed(() => props.items.length - imagesToShow)

function nextSlide() {
	if (currentIndex.value < maxIndex.value) {
		currentIndex.value++
	}
}

function prevSlide() {
	if (currentIndex.value > 0) {
		currentIndex.value--
	}
}
</script>

<template>
	<div class="relative flex items-center w-full overflow-hidden">
		<div class="w-full overflow-hidden">
			<template v-if="isHaveSlot">
				<div
					:class="`flex  transition-transform duration-500 ease-in-out gap-0`"
					:style="{ transform: `translateX(-${currentIndex * (100 / imagesToShow)}%)` }">
						<div
							v-for="(item, index) in props.items"
							:key="index"
							class="flex-shrink-0 overflow-hidden"
							>
							<slot name="item" :item="item" :index="index"></slot>
						</div>
					</div>	
			</template>
			<div
			    v-else 
				:class="`flex transition-transform duration-500 ease-in-out gap-4`"
				:style="{ transform: `translateX(-${currentIndex * (100 / imagesToShow)}%)` }">
				<div
					v-for="(image, index) in props.items"
					:key="index"
					class="flex-shrink-0 w-52 h-52 overflow-hidden"
					:style="{ width: `calc(100% / ${imagesToShow} - 16px)` }">
					<img @click="$emit('show', index)" :src="image + '?w=200&h=200'" alt="carousel image" class="w-full h-full object-cover rounded-lg" />
				</div>
			</div>
		</div>
		<button v-if="currentIndex>0"  @click="prevSlide" :class="`absolute flex items-center justify-center left-3 text-2xl border-none cursor-pointer bg-indigo-700/70 hover:bg-indigo-700 text-white w-${arrowWidth} h-${arrowWidth} rounded-full`">
			<i class="fa-solid fa-chevron-left text-sm"></i>
		</button>
		<button v-if="currentIndex<maxIndex"  @click="nextSlide" :class="`absolute right-3 flex items-center justify-center  text-2xl border-none cursor-pointer bg-indigo-700/70 hover:bg-indigo-700 text-white w-${arrowWidth} h-${arrowWidth} rounded-full`">
			<i class="fa-solid fa-chevron-right text-sm"></i>
		</button>
	</div>
</template>
